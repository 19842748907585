.roomView{
    z-index: 99;
    position: sticky;
    top: 0;
    height: 100vh;
    // min-width: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    .rv-bk{
        z-index: 0;
        position: absolute;
        top:0;
        width:100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
    }
    .rv-frame{
        position: relative;
        width: 90%;
        max-width: 640px;
        height: 90vh;
        overflow: hidden;
        margin: 0px 10px;
        border-radius: 24px;
        &:after{
            position: absolute;
            bottom: 0;
            content:'';
            width: 100%;
            height: 50px;
            background: linear-gradient(to bottom,transparent,var(--main-color));
        }
        .rv-container{
            position: relative;
            width: 100%;
            height: 100%;
            overflow: scroll;
            display: flex;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            padding:  12px 15px 50px 15px;
            background-color: var(--main-color);
            &::-webkit-scrollbar{
                display: none;
            }
            .rv-top{
                width: 80%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .rv-title{
                    flex: 1;
                    text-align: center;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: var(--main-color-light);
                }
                .rv-btn{
                    width: 50px;
                    visibility: visible;
                    box-sizing: border-box;
                    padding: 10px;
                    text-align: center;
                    background-color: var(--main-color);
                    font-size: 1.2em;
                    color: var(--main-color-light);
                    font-weight: bold;
                    cursor: pointer;
                    &:hover{
                        color: white;
                    }
                }
            }
            .rv-main-photo{
                width: 100%;
                img{
                    border-radius: 10px;
                    overflow: hidden;
                    width: 100%;
                    aspect-ratio: 4/3;
                    object-fit: cover;
                }
                @media screen and (min-width:640px) {
                    img{
                        aspect-ratio: 5/3;
                    }
                }
            }
            .rv-photo{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                img{
                    border-radius: 10px;
                    margin: 5px;
                    object-fit: cover;
                    aspect-ratio: 4/3;
                    width: 20%;
                    &:hover{
                        outline: 5px solid var(--main-color-light);
                    }
                    cursor: pointer;
                }
            }
            .rv-section{
                box-sizing: border-box;
                display: flex;
                width: 100%;
                margin: 10px 0px;
                border-radius: 10px;
                padding: 10px;
                box-shadow: inset 1px 1px 5px var(--main-color-dark);
                *{
                    color: var(--main-color-super-dark);
                    font-weight: 400;
                }
                &.wrap{
                    flex-wrap: wrap;
                    gap: 10px;
                    .rv-sec-feature{
                        box-sizing: border-box;
                        background-color: var(--main-color-light);
                        border-radius: 10px;
                        padding: 6px 10px;
                        color: var(--main-color-dark);
                        font-weight: bold;
                    }
                }
            }
            .reserveBtn{
                border-radius: 100px;
                background-color: var(--color-good);
                border: 5px solid var(--main-color-light);
                color: var(--main-color-light);
                font-size: 1.2em;
                font-weight: bold;
                padding: 5px 20px;
                margin: 10px 0px;
                box-shadow: 
                inset 1px 1px 5px var(--main-color-super-light),
                inset -1px -1px 5px var(--main-color-super-dark);
                cursor: pointer;
            }
        }
        .rv-close{
            z-index: 3;
            position: absolute;
            top:0;
            right:0;
            width: 2em;
            aspect-ratio: 1/1;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2em;
            background-color: white;
            // border: 10px solid var(--main-color);
            border-radius: 8px 8px 8px 100vmin;
            padding: 2px 2px 12px 12px;
            box-shadow: -1px 0px 5px var(--main-color-super-dark);
            // margin: 10px;
            cursor: pointer;
        }
    }
    // .rv-close{
    //     position: fixed;
    //     top:0;
    //     right:0;
    //     width: 2em;
    //     aspect-ratio: 1/1;
    //     overflow: hidden;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     font-size: 1.2em;
    //     background-color: white;
    //     border-radius: 100vmin;
    //     padding: 2px;
    //     margin: 10px;
    //     cursor: pointer;
    // }
}
