.auth{
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    overflow: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
}