.reserve{
    width: 100vw;
    height: 100svh;
    // min-height: 680px;
    // max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    .order-error{
        width: 100%;
        height: 100vh;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        z-index: 99;
        font-size: 1.2em;
        background-color: rgba(0,0,0,0.7);
        .order-error-msg{
            width: fit-content;
            color: white;
        }
        .order-error-btn{
            width: 100px;
            font-size: 1.2em;
            letter-spacing: 5px;
            border-radius: 20px;
            padding: 5px;
            cursor: pointer;
        }
    }
    .reserve-top{
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: var(--main-color);
        align-items: center;
        position: relative;
        box-sizing: border-box;
        height: 70px;
        padding-top: 10px;
        .top-container{
            z-index: 2;
            width: 95%;
            max-width: 1024px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            overflow: hidden;
            box-shadow: 
            inset 0px 30px 5px -30px var(--main-color-dark),
            inset 10px 0px 5px -10px var(--main-color-dark),
            inset -10px 0px 5px -10px var(--main-color-dark);
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .logo{
                flex: 2;
                width:20%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 2px 10px;
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }
            .session-title{
                flex: 6;
                width:60%;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                color: var(--main-color-light);
                font-size: 1.2em;
                font-weight: bold;
            }
            .right{
                flex: 2;
                width:20%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 10px;
                .right-container{
                    border: 2px solid var(--main-color-dark);
                    border-radius: 100px;
                    box-sizing: border-box;
                    padding: 4px 10px;
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    cursor: pointer;
                    span{
                        box-sizing: border-box;
                        width: fit-content;
                        color: var(--main-color-dark);
                        font-weight: bold;
                        user-select: none;
                        cursor: pointer;
                    }

                }
            }
        }
    }
    .reserve-middle{
        box-sizing: border-box;
        height: calc(100svh - 90px);
        width: 95%;
        max-width: 1024px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--main-color);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        box-shadow: 
        inset 5px -3px 5px -5px var(--main-color-dark),
        inset -5px -3px 5px -5px var(--main-color-dark);
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            display: none;
        }
        .session-item{
            box-sizing: border-box;
            width: 96%;
            max-width: 480px;
            height: 96%;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: height 2s, opacity 1s;
            background-color: var(--main-color);
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar{
                display: none;
            }
            &.closed{
                height: 0px;
                opacity: 0;
            }
            .session-item-top{
                height: 100%;
                position: relative;
                width: 100%;
                &.scroll{
                    height: 100%;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                }
            }
            // .next-button-container{
            //     box-sizing: border-box;
            //     width: 90%;
            //     min-height: 100px;
            //     display: flex;
            //     align-items: center;
            //     margin-top: auto;
            //     justify-self: flex-end;
            //     .next-button{
            //         border: 5px solid var(--main-color-light);
            //         background-color: var(--main-color);
            //         box-sizing: border-box;
            //         width: 100%;
            //         height: 50px;
            //         box-shadow: 
            //         inset 2px 3px 5px -2px var(--main-color-light),
            //         inset -2px -3px 10px -2px var(--main-color-super-dark);
            //         border-radius: 100px;
            //         overflow: hidden;
            //         // max-width: 480px;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         .next-session{
            //             flex: 3;
            //         }
            //         .previous-session{
            //             flex: 1;
            //             border-left: 1px solid var(--main-color-dark);
            //         }
            //         button{
            //             appearance: none;
            //             height: 50px;
            //             font-size: 1.2em;
            //             font-weight: bold;
            //             color: var(--main-color-super-dark);
            //             border: none;
            //             background-color: transparent;
            //             cursor: pointer;
            //             &:hover{
            //                 background-color: rgba(0,0,0,0.1);
            //             }
            //             &:disabled{
            //                 color: var(--main-color-light);
            //                 cursor: not-allowed;
            //                 &:hover{
            //                     background-color: transparent;
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    // .reserve-bottom{
    //     margin-top: auto;
    //     justify-self: flex-end;
    //     height: 70px;
    //     width: 95%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     max-width: 1024px;
    //     margin-bottom: 50px;
    //     border-bottom-left-radius: 25px;
    //     border-bottom-right-radius: 25px;
    //     box-shadow: 
    //         inset 0px -30px 5px -30px var(--main-color-dark),
    //         inset 10px 0px 5px -10px var(--main-color-dark),
    //         inset -10px 0px 5px -10px var(--main-color-dark);
    //     .next-button{
    //         box-shadow: 
    //         inset 2px 3px 5px -2px var(--main-color-light),
    //         inset -2px -3px 10px -2px var(--main-color-super-dark);
    //         border-radius: 100px;
    //         overflow: hidden;
    //         width: 96%;
    //         max-width: 480px;
    //         height: 50px;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         .next-session{
    //             flex: 3;
    //             border-right: 1px solid var(--main-color-dark);
    //         }
    //         .previous-session{
    //             flex: 1;
    //         }
    //         button{
    //             appearance: none;
    //             height: 50px;
    //             font-size: 1.2em;
    //             font-weight: bold;
    //             color: var(--main-color-super-dark);
    //             border: none;
    //             background-color: transparent;
    //             cursor: pointer;
    //             &:hover{
    //                 background-color: rgba(0,0,0,0.1);
    //             }
    //             &:disabled{
    //                 color: var(--main-color-light);
    //                 cursor: not-allowed;
    //                 &:hover{
    //                     background-color: transparent;
    //                 }
    //             }
    //         }
    //     }
        
    // }
}
