.home{
    // min-width: 390px;
    width: 100vw;
    // height: 100vh;
    position: relative;
    .home-layer-2{
        z-index: 1;
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
    }
    .home-layer-1{
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: var(--main-color);
        .home-bottom{
            margin-top: var(--navbar-height);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: calc(100% - 80px);
            overflow: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            .home-container{
                width: 95%;
                max-width: 1024px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--main-color);
                box-shadow: inset 5px 0px 5px -5px var(--main-color-dark),inset -5px 0px 5px -5px var(--main-color-dark);
                .hm-cover{
                    display: flex;
                    margin: 0px 0px 20px 0px;
                    width: 100%;
                    height:auto;
                    aspect-ratio: 3/1;
                    overflow: hidden;
                    position: relative;
                    pointer-events: none;
                    .hm-button-container{
                        *{
                            user-select: none;
                        }
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: inset 0px 0px 5px 5px var(--main-color-dark);
                        button{
                            color: var(--main-color-light);
                            letter-spacing: 10px;
                            padding: 10px 0px 10px 10px;
                            border: 2px solid var(--main-color-dark);
                            border-radius: 100vmin;
                            background-color: rgba($color: #887b40, $alpha: 0.8);
                            cursor: pointer;
                            pointer-events: auto;
                            &:hover{
                                color: var(--main-color-dark);
                                background-color: rgba(256,256,256,0.8);
                            }
                        }
                    }
                    img{
                        z-index: 0;
                        object-fit:cover;
                        box-sizing: border-box;
                        width: 100% ;
                        transition: filter ease-in-out 1s;
                    } 
                    &:hover{
                        img{
                            filter: blur(5px);
                            transition: filter ease-in-out 1s;
                        }
                    }         
                }
                .hm-map{
                    margin: 10px 0px;
                    border: none;
                    border-radius: 8px;
                    overflow: hidden;
                    box-sizing: border-box;
                    width: calc(100% - 28px);
                    aspect-ratio: 2/1;
                    // width: 95%;
                }
                .hm-facebook{
                    margin: 10px 0px 10px 14px;
                    align-self: flex-start;
                    border: none;
                    border-radius: 8px;
                    overflow: hidden;
                    box-sizing: border-box;
                    max-width: calc(100% - 28px);
                    width: 500px;
                    height: 130px;
                    // width: 95%;
                }
                .hm-section{
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0px 14px;
                    margin-bottom: 20px;
                    .hm-sec-title{
                        font-size: 1.2em;
                        font-weight: bold;
                        color: var(--main-color-light);
                        margin-bottom: 14px;
                    }
                    .hm-sec-container{
                        width: 100%;
                        *:not(.bold){
                            font-weight: 400;
                            color: var(--main-color-super-dark);
                        }
                    }
                    .hm-sec-container.hm-rules{
                        box-sizing: border-box;
                        display: flex;
                        &>*{
                            width: 100%;
                            box-sizing: border-box;
                            position: relative;
                            padding: 10px 0px 20px 0px;
                        }
                        &>*>*{
                            box-sizing: border-box;
                            padding: 0px 20px 0px 10px;
                        }
                        &>*>*::after{
                            content:'';
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 3px;
                        }
                    }
                    .hm-room-item{
                        width: 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        box-sizing: border-box;
                        background-color: var(--main-color-light);
                        border-radius: 10px;
                        min-width: 150px;
                        padding: 6px;
                        cursor: pointer;
                        img{
                            border-radius: 10px 10px 0px 0px;
                            aspect-ratio: 1/1;
                            width: 100%;
                            object-fit: cover;
                        }
                        .hm-room-i-title{
                            display: flex;
                            box-sizing: border-box;
                            align-items: center;
                            gap: 10px;
                            margin: 10px 0px;
                            width: fit-content;
                            font-size: 1.2em;
                            span{
                                font-weight: bold;
                                color: var(--main-color);
                            }
                            .hm-room-i-roomNumber{
                                border-radius: 10px;
                                background-color: var(--main-color);
                                padding: 0px 10px;
                                font-weight: bold;
                                color: var(--main-color-light);
                                text-shadow: 1px 1px 1px var(--main-color-dark);
                            }
                        }
                    }
                    .hm-post-item{
                        width: 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        box-sizing: border-box;
                        background-color: var(--main-color-light);
                        border-radius: 10px;
                        min-width: 150px;
                        padding: 6px;
                        cursor: pointer;
                        img{
                            border-radius: 10px 10px 0px 0px;
                            aspect-ratio: 1/1;
                            width: 100%;
                            object-fit: cover;
                        }
                        .hm-post-title{
                            display: flex;
                            box-sizing: border-box;
                            align-items: center;
                            gap: 10px;
                            margin: 10px 0px;
                            width: fit-content;
                            font-size: 1.2em;
                            font-weight: bold;
                            color: var(--main-color);
                            word-break: break-all;
                        }
                    }
                    &.photo{
                        *{
                            gap: 0;
                        }
                        img{
                            box-sizing: border-box;
                            margin: 5px;
                            border-radius: 10px;
                            object-fit: cover;
                            aspect-ratio: 3/2;
                            width: 100px;
                            cursor: pointer;
                            &:hover{
                                outline: 5px solid var(--main-color-light);
                            }
                        }
                    }
                    @media screen and (min-width: 640px) {
                        .hm-sec-container.hm-rules{
                            flex-wrap: wrap;
                            &>*{
                                box-sizing: border-box;
                                width: 50%;
                                min-height: 200px;
                            }
                            &>*>*::after{
                                background: linear-gradient(to right, var(--main-color), var(--main-color-dark) 20%, var(--main-color));
                            }
                        }
                        .hm-room-item{
                            width: 19%;
                        }
                    }

                    // /* Safari Only */
                    // @supports selector(:nth-child(1 of x)) {
                    //     .hm-cover{
                    //         height:10em
                    //     }
                    // }
                }
                @media screen and (max-width: 640px) {
                    .hm-cover{
                        aspect-ratio: 5/4;
                        justify-content: center;
                        align-items: flex-start;
                        img{
                            translate: 0px 0px;
                            object-fit: cover;
                            // width: auto;
                            // height: 100%;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .hm-sec-container.hm-rules{
                        display: flex;
                        flex-direction: column;
                        &>*>*::after{
                            background: linear-gradient(to right, var(--main-color-dark) 50%, var(--main-color));
                        }
                    }
                }
            }
        }
    
    }
}
