.itemList{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        hr{
            width: 100%;
            background-color:transparent;
            border: none;
            border-top: 2px dotted var(--main-color-dark);
        }
        .subtitle{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 0px 0px 5px 0px;
            .title{
                font-size: 1.2em;
                color: var(--main-color-dark);
                font-weight: 600;
            }
            .edit{
                width: 100px;
                display: flex;
                gap: 5px;
                border-radius: 10px;
                overflow: hidden;
                .btn{
                    background-color: var(--main-color-light);
                    color: var(--main-color-super-dark);
                    font-weight: 500;
                    border: none;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .il-frame{
            display: flex;
            width: 100%;
            position: relative;
            .il-container{
                position: relative;
                z-index: 0;
                width: 100%;
                display: flex;
                gap:5px;
            }
        }
        @media screen and (min-width: 640px) {
            .il-container{
                flex-wrap: wrap;
                gap: 10px;
            }
        }
        @media screen and (max-width: 640px){
            .il-frame{
                &::after{
                    content: '';
                    position:absolute;
                    width: 50px;
                    height: 100%;
                    right: 0;
                    background: linear-gradient(to right, transparent, var(--main-color))
                }
            }
            .il-container{
                overflow: scroll;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
}