.check-form{
    box-sizing: border-box;
    min-height: fit-content;
    width: 100%;
    max-width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    .c-f-section{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .field-container{
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            gap: 5px;
            font-size: 1.2em;
            .field-item{
                padding: 0px 5px 0px 5px;
                width: 90%;
                display: grid;
                box-sizing: border-box;
                grid-template-columns: auto auto;
                border-bottom: 1px dashed var(--main-color-dark);
                *{
                    color: var(--main-color-super-dark);
                    font-weight: 400;
                }
                .fd-title{
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 0;
                    grid-row-end: 1;
                    justify-self: start;
                    height: fit-content;
                    padding-right: 12px;
                }
                .fd-value, .fd-input, .fd-select{
                    grid-column-start: 2 ;
                    grid-column-end: 3 ;
                    grid-row-start: 0;
                    grid-row-end: 1;
                    justify-self: start;
                    height: 100%;
                    width: 100%;
                    word-break: break-all;
                    padding-left: 5px;
                    box-sizing: border-box;
                    border: none;
                    outline: none;
                    &::-webkit-inner-spin-button{
                        display: none;
                        margin: 0;
                    }
                }
                .fd-input, .fd-select{
                    background-color: var(--main-color-light);
                }
                .fd-error{
                    grid-column-start: 2;
                    grid-row-start: 2;
                    justify-self: end;
                    color: darkred;
                    font-weight: 500;
                    word-break: break-all;
                }
            }
        }
        .c-f-s-title{
            padding: 5px 0px;
            color: var(--main-color-dark);
            font-weight: bold;
            font-size: 1.2em;
        }
        .c-f-s-container{
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
            box-sizing: border-box;
            .c-f-item{
                width: 100%;
                margin-bottom: 5px;
                box-sizing: border-box;
                display: flex;
                border: none;
                &.big{
                    grid-column: 1/3;
                }
                .c-f-i-title{
                    box-sizing: border-box;
                    width: 50px;
                    padding-left: 5px;
                    color: var(--main-color-super-dark);
                }
                input{
                    width: calc(100% - 50px);
                    box-sizing: border-box;
                    margin-right:5px;
                    outline: none;
                    border: none;
                }
            }
        }
        .agreement{
            display: flex;
            flex-direction: column;
            align-self: center;
            width: 96%;
            height: calc(100vh*0.4);
            box-sizing: border-box;
            margin: 10px 0px;
            padding: 10px;
            background-color: var(--main-color-light);
            border-radius: 15px;
            overflow: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            &:not(.ag-bottom){
                color: var(--main-color-super-dark);
            }
            .ag-bottom{
                align-self: center;
                margin-top: auto;
                display: flex;
                align-items: center;
                bottom: 0;
                font-size: 1.2rem;
                cursor: pointer;
                .agree{
                    margin-top: 30px;
                    box-sizing: border-box;
                    font-weight: bold;
                    color: var(--main-color);
                    background-color: var(--main-color-super-dark);
                    border-radius: 100px;
                    padding: 5px 15px;
                    text-align: center;
                    width: fit-content;
                }
            }
        }
        .errors{
            display: flex;
            justify-content: flex-end;
            padding: 0px 10px;
            gap: 10px;
        }
    }
}