.order{
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    overflow: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
    .error{
        position: fixed;
        top:20%;
        width: 100%;
        height: 100%;
        text-align: center;
        color:var(--main-color-super-dark);
        font-size: 24px;
        font-weight: 500;
    }
}