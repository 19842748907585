.reserve-btn{
    width: 100%;
    margin-top: auto;
    .next-button-container{
        box-sizing: border-box;
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        // margin-top: auto;
        justify-self: flex-end;
        .next-button{
            border: 5px solid var(--main-color-light);
            background-color: var(--main-color);
            box-sizing: border-box;
            width: 100%;
            height: 50px;
            box-shadow: 
            inset 2px 3px 5px -2px var(--main-color-light),
            inset -2px -3px 10px -2px var(--main-color-super-dark);
            border-radius: 100px;
            overflow: hidden;
            // max-width: 480px;
            display: flex;
            justify-content: center;
            align-items: center;
            .next-session{
                flex: 6;
            }
            .previous-session{
                flex: 4;
                border-left: 1px solid var(--main-color-dark);
            }
            button{
                appearance: none;
                height: 50px;
                font-size: 1em;
                font-weight: bold;
                color: var(--main-color-super-dark);
                border: none;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                    background-color: rgba(0,0,0,0.1);
                }
                &:disabled{
                    color: var(--main-color-light);
                    cursor: not-allowed;
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
    }
}