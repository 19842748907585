.footer{
    align-self: flex-end;
    width: 100%;
    height: var(--footer-height);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background-color: var(--main-color);
    .footer-container{
        box-sizing: border-box;
        width: 95%;
        max-width: 1024px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        box-shadow: 
            inset 0px -30px 5px -30px var(--main-color-dark),
            inset 10px 0px 5px -10px var(--main-color-dark),
            inset -10px 0px 5px -10px var(--main-color-dark);
        .footer-info{
            color: var(--main-color-super-dark);
            list-style: none;
            padding: 0px;
            margin-top: 10px;
            width: inherit;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            .footer-info-item{
                padding: 0px;
                margin: 0px;
            }
        }
        .footer-copyright{
            font-size: 0.8em;
            color: var(--main-color-light);
            margin: 10px 2px 20px 2px;
        }
    }
}