.orderView{
    box-sizing: border-box;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .ov-bk{
        z-index: 0;
        position: fixed;
        top:0;
        width:100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
    }
    .ov-container-border{
        z-index: 1;
        border:1px solid black;
        position: relative;
        min-width: 320px;
        max-width: 420px;
        height: 90vh;
        border-radius: 24px;
        background-color: var(--main-color);
        box-sizing: border-box;
        overflow: hidden;
        .ov-close{
            z-index: 3;
            position: absolute;
            top:0;
            right:0;
            width: 2em;
            aspect-ratio: 1/1;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2em;
            background-color: white;
            // border: 10px solid var(--main-color);
            border-radius: 8px 8px 8px 100vmin;
            padding: 2px 2px 12px 12px;
            box-shadow: -1px 0px 5px var(--main-color-super-dark);
            // margin: 10px;
            cursor: pointer;
        }
        .ov-container{
            position: relative;
            // min-width: 390px;
            // width: 420px;
            height: 90vh;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding:  12px 15px 0px 15px;
            overflow: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            .ov-deposit-section{
                box-sizing: border-box;
                min-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 24px 24px 0px 0px;
                border: 1px solid var(--main-color-dark);
                box-shadow: inset 5px 0px 5px -5px var(--main-color-dark),inset -5px 0px 5px -5px var(--main-color-dark);
                margin-bottom: 15px;
                .ov-ds-status{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    border-radius: 24px 24px 0px 0px;
                    box-shadow: inset 5px 0px 5px -5px var(--main-color-dark),inset -5px 0px 5px -5px var(--main-color-dark);;
                    width: 100%;
                    min-height: 45px;
                    span{
                        font-size: 1.2em;
                        color: var(--main-color-super-light);
                        font-weight: bold;
                    }
                    &.pending{
                        background-color: var(--color-warning);
                    }
                    &.confirming , &.waiting{
                        background-color: var(--color-notice);
                    }
                    &.approved{
                        background-color: var(--color-good);
                    }
                    &.completed{
                        background-color: var(--color-good);
                    }
                }
                .ov-ds-info{
                    margin-bottom: 10px;
                    width: 95%;
                    min-height: fit-content;
                    color: var(--main-color-super-dark);
                    font-weight: 400;
                }
                .custom-form-btn{
                    width: 100%;
                    border-radius: 8px;
                    font-size: 1.2em;
                    color: var(--main-color-dark);
                    text-align: center;
                    background-color: var(--main-color-light);
                    cursor: pointer;
                }
            }
            .request-container{
                box-sizing: border-box;
                width: 100%;
                display: flex; 
                flex-direction: column;
                align-items: center;
                padding: 5px;
                gap: 5px;
                font-size: 1.2em;
                .request-item{
                    width: 90%;
                    background-color: var(--main-color-light);
                    border-radius: 15px 15px 15px 15px;
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    padding-bottom: 10px;
                    *{
                        font-weight: 400;
                        color: var(--main-color-dark);
                    }
                    .req-top{
                        padding: 4px 0px;
                        font-weight: 400;
                        color: var(--main-color-super-dark);
                        border-radius: 15px 15px 0px 0px;
                        width: 100%;
                        text-align: center;
                        background-color: var(--color-notice);
                        &.reject{
                            background-color: var(--color-warning);
                        }
                        &.fullyApproved{
                            background-color: var(--color-good);
                        }
                    }
                    .req-middle ,.req-bottom{
                        box-sizing: border-box;
                        padding: 5px 10px 0px 10px;
                        width: 100%;
                        display: flex;
                        gap: 10px;
                        .req-title{
                            width:fit-content;
                            word-break: keep-all;
                        }
                        .req-container{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
            &::after{
                position: sticky;
                box-sizing: border-box;
                bottom: 0px;
                content: '';
                min-width: 100%;
                min-height: 50px;
                background: linear-gradient(to bottom, transparent, var(--main-color))
            }
            .ov-cancel-order{
                color: var(--main-color-light);
                box-sizing: border-box;
                border-radius: 100vmin;
                font-size: 1.2em;
                padding: 10px;
                text-align: center;
                width: 100%;
                background-color: var(--color-warning);
                box-shadow: 
                inset 1px 1px 5px var(--main-color-super-light),
                inset -1px -1px 5px var(--main-color-super-dark);
                cursor: pointer;
            }
        }

    }
    .ov-layer-2{
        z-index: 4;
        top:0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        .ov-confirm-btn{
            width: 300px;
            display: grid;
            // grid-template-columns: auto;
            // grid-template-rows: auto;
            background-color: var(--main-color);
            box-sizing: border-box;
            padding: 20px;
            border-radius: 8px;
            justify-items: stretch;
            gap: 10px;
            span{
                padding: 10px;
                font-size: 1.2em;
                white-space: nowrap;
                text-align: center;
                grid-row: 1 / 2;
                grid-column: 1 / 3;
                color: var(--main-color-super-dark);
            }
            button{
                font-size: 1.2em;
                width: 100%;
                text-align: center;
                border: none;
                color: var(--main-color-super-dark);
                border-radius: 8px;
                cursor: pointer;
            }
            .ov-btn-no{
                grid-row: 2 / 3;
                grid-column: 1 / 2;
                background-color: var(--color-notice);
            }
            .ov-btn-yes{
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                background-color: var(--color-warning);
            }
        }
    }
    &.normal{
        position: relative;
        height: 100%;
        width: 100%;
        .ov-container-border{
            position: relative;
            border:none;
            border-radius: 0px;
            height: 100%;
            width: 96%;
            .ov-container{
                position: relative;
                border:none;
                height: 100%;
                width: 100%;
                padding:0;
                padding-top: 10px;

            }
        }
    }
}