.login-register-container{
    position: absolute;
    top:0;
    bottom:0;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    .lr-login-policy-container{
        position: fixed;
        z-index: 999;
        left: 0;
        top:0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        .lr-login-policy-content{
            background-color: white;
            width:80%;
            max-width: 320px;
            border-radius: 10px;
            padding: 10px;
            z-index: 999;
            .lr-login-policy-btns{
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
                .btn{
                    color:white;
                    border: none;
                    padding: 4px 10px 4px 10px;
                    border-radius: 10px;
                    &:hover{
                        cursor: pointer;
                    }
                    &.agree{
                        background-color: green;
                    }
                    &.disagree{
                        background-color: red;
                    }
                }
            }
        }
    }
    .lr-page{
        // border:1px solid black;
        height: 100%;
        overflow-x: hidden;
        flex: 1;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        // opacity: 1;
        // transition: ease-in-out 1.5s;
        // &.show{
        //     flex: 1;
        //     position: relative;
        //     opacity: 1;
        //     transition: ease-in-out 1.5s;
        // }
        .lr-form{
            box-sizing: border-box;
            border-radius: 20px;
            box-shadow: inset 2px 2px 10px 1px var(--main-color-dark);
            width: 100%;
            max-width: fit-content;
            height: fit-content;
            padding: 20px 20px;
            margin: 20px 0px;
            background-color: var(--main-color);
            overflow-x: scroll;
            overflow-y: hidden;
            &::-webkit-scrollbar{
                display: none;
            }
            .lr-field{
                box-sizing: border-box;
                border-bottom: 1px dashed var(--main-color-light);
                display: flex;
                padding: 5px 0px;
                gap: 5px;
                &:nth-last-child(1){
                    border-bottom:none;
                }
                label, .lr-fld-title{
                    min-width: 100px;
                    letter-spacing: 2px;
                    font-weight: bold;
                    font-size: 1.2em;
                    color: var(--main-color-light);
                }
                .lr-fld-right{
                    input{
                        border: none;
                        background-color: var(--main-color-super-light);
                        outline-style: none;
                        border-radius: 5px;
                        padding: 10px;
                    }
                    .lr-fld-error{
                        color: var(--color-warning);
                        font-weight: bold;
                    }
                }
            }
        }
        .lr-buttons{
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            min-height: fit-content;
            height: 100%;
            button, .lr-button{
                border: 5px solid var(--main-color-light);
                white-space: nowrap;
                background-color: var(--main-color);
                color: var(--main-color-super-light);
                font-weight: bold;
                border-radius: 100vmin;
                padding: 10px 50px;
                font-size: 1.2em;
                box-shadow: 
                inset 1px 1px 5px var(--main-color-super-light),
                inset -1px -1px 5px var(--main-color-super-dark);
                cursor: pointer;
            }
            .lr-button{
                overflow: hidden;
                padding: 0;
            }
        }
    }
}