.information{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    .line{
        .bold{
            font-weight: bold;
        }
    }
}