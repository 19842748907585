.form{
    width: 100%;
    max-width: 600px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    .top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 5px 0px;
        .title{
            color: var(--main-color-dark);
            font-weight: bold;
            font-size: 1.2em;
        }
        .btn-container{
            width: 150px;
            display: flex;
            gap: 5px;
            border-radius: 10px;
            overflow: hidden;
            .btn{
                background-color: var(--main-color-light);
                color: var(--main-color-dark);
                // font-weight: bold;
                font-size: 1em;
                border: none;
                height: 100%;
                width: 100%;
                &:hover{
                    background-color: var(--main-color-super-light);
                }
            }
        }
    }
    .cld-container{
        box-sizing: border-box;
        width: 100%;
        // display: flex; 
        // flex-direction: column;
        // align-items: center;
        padding: 5px;
        // gap: 5px;
        // font-size: 1.2em;
    }
    .field-container{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        gap: 5px;
        font-size: 1.2em;
        .field-item{
            padding: 0px 5px 0px 5px;
            width: 90%;
            display: grid;
            box-sizing: border-box;
            border-bottom: 1px dashed var(--main-color-dark);
            *{
                color: var(--main-color-super-dark);
                font-weight: 400;
            }
            .fd-title{
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 0;
                grid-row-end: 1;
                justify-self: start;
                height: fit-content;
            }
            .fd-value, .fd-input, .fd-select{
                grid-column-start: 2 ;
                grid-column-end: 3 ;
                grid-row-start: 0;
                grid-row-end: 1;
                justify-self: start;
                height: 100%;
                width: 100%;
                word-break: break-all;
                padding-left: 5px;
                box-sizing: border-box;
                border: none;
                outline: none;
                &::-webkit-inner-spin-button{
                    display: none;
                    margin: 0;
                }
            }
            .fd-input, .fd-select{
                background-color: var(--main-color-light);
            }
            .fd-items{
                grid-column-start: 2 ;
                grid-column-end: 3 ;
                grid-row-start: 0;
                grid-row-end: 1;
                display: flex;
                flex-wrap: wrap;
                padding-left: 5px;
                // gap: 1em;
                .fd-item{
                    &:not(:last-child)::after{
                        content: '/';
                        margin: 0px 5px;
                    }
                    &.btn{
                        margin: 5px;
                        padding: 3px 6px;
                        border-radius: 5px;
                        background-color: var(--main-color-light);
                        cursor: pointer;
                        &:not(:last-child)::after{
                            content: '';
                            margin: 0;
                        }
                    }
                    &.selected{
                        outline: 3px solid red;
                    }
                }
            }
            textarea{
                border: none;
                resize: none;
                background-color: var(--main-color-light);
            }
            .fd-error{
                grid-column-start: 2;
                grid-row-start: 2;
                justify-self: end;
                color: darkred;
                font-weight: 500;
                word-break: break-all;
            }
        }
    }
}