.home-button{
    height: 100%;
    img{
        height: 100%;
        cursor: pointer;
        filter: 
        contrast(100%) 
        brightness(50%) 
        drop-shadow(2px 2px 2px var(--main-color-dark));
        &:hover{
            filter:
            contrast(100%) 
            brightness(50%) 
            blur(1px);
        }
    }
    
}