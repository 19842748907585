.order-form{
    box-sizing: border-box;
    width: 100%;
    max-width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    
    .o-f-item{
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        gap: 6px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--main-color-dark);
        .o-f-i-top{
            display: flex;
            *{
                font-weight: bold;
            }
            .o-f-i-left{
                font-size: 1.2em;
                box-sizing: border-box;
                flex: 1;
                color: var(--main-color-dark);
            }
            .o-f-i-right{
                font-size: 1em;
                flex: 2;
                display: flex;
                *{
                    color: var(--main-color-super-dark);
                    }  
                &.column{
                    flex-direction: column;
                }
                input, select{
                    width: 100%;
                    background-color: var(--main-color-light);
                    border: none;
                    cursor: pointer;
                    &,*{
                        color: var(--main-color-dark);
                        text-align: center;
                    }
                }
            }
        }
        p{
            font-weight: bold;
            color: var(--main-color-super-dark);
            width: fit-content;
            align-self: center;
        }
        .o-f-i-container{
            width: 100%;
            height: fit-content;
            padding: 5px 0px;
            display: flex;
            &.wrap{
            flex-wrap: wrap;
            gap: 5px;
            }
            .room-item{
                box-sizing: border-box;
                padding: 6px;
                width: 8em;
                display: flex;
                flex-direction: column;
                border-radius: 4px;
                background-color: var(--main-color-light);
                cursor: default;
                user-select: none;
                *{
                    font-size: 1em;
                    font-weight: bold;
                    color: var(--main-color-dark);
                }
                .room-price{
                    display: flex;
                    width: 100%;
                    .room-number{
                        width: fit-content;
                        background-color: var(--main-color-dark);
                        color: var(--main-color-light);
                        border-radius: 5px;
                        padding: 1px 5px;
                    }
                    .right{
                        width: 100%;
                        margin-left: 5px;
                        justify-self: end;
                        text-align: end;
                        white-space: nowrap;
                    }
                    .left{
                        width: 100%;
                        justify-self: flex-start;
                        white-space: nowrap;
                    }
                }
                &.clickable *{
                    cursor: pointer;
                }
                &.clicked{
                    outline: 4px solid red;
                }
                // &.clickable:hover{
                //     outline: 4px solid red;
                // }
            }
            // .discount-item{
            //     width: fit-content;
            //     display: flex;
            //     flex-direction: column;
            //     border: none;
            //     border-radius: 5px;
            //     background-color: var(--main-color-light);
            //     padding: 0px 4px;
            //     *{
            //         font-size: 12px;
            //         font-weight: bold;
            //         color: var(--main-color-dark);
            //         cursor: pointer;
            //     }
            //     .discount-id{
            //         display: flex;
            //         width: 100%;
            //     }
            //     .discount-amount{
            //         display: flex;
            //         width: 100%;
            //     }
            //     &.clicked{
            //         outline: 4px solid red;
            //     }
            // }    
        }
    }
    .o-f-result{
        // margin-top: 10px;
        // align-self: flex-end;
        // margin-bottom: 5px;
        box-sizing: border-box;
        padding: 10px;
        *{
            font-size: 1.2rem;
            font-weight: bold;
            color: var(--main-color-super-dark);
        }
        .o-f-r-top{
            box-sizing: border-box;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid var(--main-color-dark);
        }
        .o-f-r-bottom{
            margin-top: 10px;
            box-sizing: border-box;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            .o-f-r-price{
                box-sizing: border-box;
                height: fit-content;
                border-radius: 5px;
                border: 3px solid var(--main-color-super-dark);
                padding: 2px 5px;
            }        
        }       
    }
    textarea{
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: var(--main-color-light);
        margin-bottom: 5px;
        padding: 5px;
        width: 100%;
        height: 100px;
        resize: none;
    }
}