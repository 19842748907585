.calendar{
    box-sizing: border-box;
    padding: 0;
    border-radius: 8px;
    // overflow: hidden;
    position: relative;
    height: 100%;
    transition: height 1s, opacity 1s;
    .choose-month{
        box-sizing: border-box;
        height: 2em;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0em;
        right: 0;
        z-index: 1;
        transition: visibility 1s;
        font-size: 1.2em;
        font-weight: bold;
        color: var(--main-color-super-dark);
        select{
            margin-left: 0.5em;
            padding: 0em 0.5em;
            box-sizing: border-box;
            border: none;
            outline: none;
            background-color: var(--main-color-light);
            color: var(--main-color-super-dark) !important;
            border-radius: 0;
            cursor: pointer;
        }
    }
    .calendar-container{
        box-sizing: border-box;
        height: 100%;
        z-index: 0;
        background-color: var(--main-color);
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        .month{
            box-sizing: border-box;
            position: relative;
            .month-display{
                box-sizing: border-box;
                height: 2em;
                width: calc(100% - 100px);
                position: sticky;
                top: 0;
                background: linear-gradient(to right,var(--main-color),var(--main-color-light));
                text-shadow: 1.2px 1.2px 4px var(--main-color-dark);
                letter-spacing: 2px;
                color: var(--main-color-super-dark);
                font-size: 1.2em;
                font-weight: bold;
                display: flex;
                align-items: center;
                padding-left: 0.5em;
                &::after{
                    content: '';
                    position: absolute;
                    right:-100px;
                    width: 100px;
                    height: 2em;
                    background-color: var(--main-color-light);
                }
            }
        }
        .item{
            // background-color: var(--main-color);
            box-sizing: border-box;
            height: fit-content;
            display: flex;
            padding: 4px 0px;
            border-bottom: 2px solid var(--main-color-dark);
            .item-left{
                flex: 2;
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding: 5px 5px 5px 5px;
                .date-info{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    span{
                        font-size: 1.2em;
                        font-weight: bold;
                        text-shadow: 1px 1.5px 3px var(--main-color-dark);
                        color: var(--main-color-light);
                        cursor: default;
                    }
                }
                .date-type{
                    width: fit-content;
                    padding: 2px 8px;
                    border-radius: 8px;
                    background-color: lightcoral;
                    color: var(--main-color-dark);
                    font-weight: bold;
                    cursor: default;
                    &.normal{
                        background-color: lightblue;
                    }
                }
            }
            .item-right{
                flex: 6;
                display: flex;
                gap: 5px;
                flex-wrap: wrap;
                padding: 5px 0px 5px 0px;
                .room-item{
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 5em;
                    height:fit-content;
                    border-radius: 8px;
                    padding: 2px 8px;
                    font-weight: bold;
                    color: var(--main-color-dark);
                    cursor: pointer;
                    *{
                        width: 100%;
                    }
                    &.availible{
                        background-color: var(--main-color-light);
                    }
                    &.waiting{
                        color: var(--main-color-light);
                        background-color: var(--main-color-dark);
                    }
                    &.clickable{
                        cursor: pointer;
                    }
                    &.clicked{
                        outline: 4px solid red;
                    }
                }
            }
            &.last{
                border: none;
            }
        }
    }
}   