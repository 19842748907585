.body{
    margin-top: var(--navbar-height);
    box-sizing: border-box;
    max-width: 1024px;
    width: 95%;
    height: 100%;
    min-height: calc(100vh - (var(--navbar-height)) - (var(--footer-height)));
    max-height: 100%;
    background-color: var(--main-color);
    box-shadow: inset 5px 0px 5px -5px var(--main-color-dark),inset -5px 0px 5px -5px var(--main-color-dark);
    position: relative;
    // transition: height 5s;
    // overflow: scroll;
    // &::-webkit-scrollbar{
    //     display: none;
    // }
}