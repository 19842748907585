.user{
    box-sizing: border-box;
    // min-width: 390px;
    width: 100vw;
    min-height: 100vh;
    position: relative;
    .user-layer-2{
        z-index: 1;
        box-sizing: border-box;
        position:fixed;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .user-layer-1{
        z-index: 0;
        box-sizing: border-box;
        position: absolute;
        top:0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--main-color);
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        .user-container{
            margin: 20px auto;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 90%;
        }
    }
    .btn-delete-user{
        padding:4px 40px 4px 40px;
        border:none;
        border-radius: 12px;
        background-color: orangered;
        color:var(--main-color-light);
        font-weight: 500;
        cursor: pointer;
    }
    .order-item{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 5px 10px;
        border-radius: 15px 0px 15px 0px;
        background-color: var(--main-color-light);
        cursor: pointer;
        &.waiting{
            background-color: yellow;
        }
        color: var(--main-color-dark);
        font-size: 1.2em;
        font-weight: 500;
        *{
            width: max-content;
        }
    }
    .discount-item{
        min-width: 150px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 5px 10px;
        border-right: 4px dashed var(--main-color);
        background-color: var(--main-color-light);
        cursor: default;
        color: var(--main-color-dark);
        font-size: 1.2em;
        font-weight: 500;
    }
    hr{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 5px;
        background-color:transparent;
        border: none;
        border-top: 2px dotted var(--main-color-dark);
    }
}