.choose-date-room{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 480px;
    height: 100%;
    min-height: 400px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
    .c-d-r-layer-1{
        .c-d-r-switch-button{
            border:none;
            border-radius: 10px;
            color:var(--main-color-light);
            background-color: var(--main-color-dark);
            cursor: pointer;
        }
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0px 6px;
        .c-d-r-top{
            position: relative;
            box-sizing: border-box;
            min-height: 200px;
            height: 100%;
            max-height: 500px;
            padding: 4px;
            border-radius: 8px;
            box-shadow: 
            inset 5px 0px 5px -5px var(--main-color-dark),
            inset 0px 0px 10px 0px var(--main-color-dark),
            inset -5px 0px 5px -5px var(--main-color-dark),
            inset -5px 0px 5px -5px var(--main-color-dark)
            ;
            // overflow: hidden;
            // overflow-y: scroll;
            // &::-webkit-scrollbar{
            //     display: none;
            // }
        }
        .c-d-r-bottom{
            overflow: hidden;
            margin-top: 5px;
            box-sizing: border-box;
            border-radius: 8px;
            width: 100%;
            overflow: visible;
            overflow-wrap: anywhere;
            display: flex;
            flex-direction: column;
            padding: 6px;
            background-color: var(--main-color);
            box-shadow: 
            inset 5px 0px 5px -5px var(--main-color-dark),
            inset 0px 0px 10px 0px var(--main-color-dark),
            inset -5px 0px 5px -5px var(--main-color-dark),
            inset -5px 0px 5px -5px var(--main-color-dark)
            ;
            .c-d-r-choose-date{
                box-sizing: border-box;
                padding: 5px;
                height: 100%;
                display: flex;
                flex-direction: column;
                *{
                    font-weight: bold;
                    color: var(--main-color-dark);
                }
                .c-d-r-choose-date-items{
                    height: 100%;
                    display: flex;
                    background-color: var(--main-color-super-light);
                    gap: 10px;
                    border-radius: 8px;
                    align-items: center;
                    overflow: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                    .choose-date-item{
                        flex:1;
                        box-sizing: border-box;
                        // width: 100%;
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        padding: 5px;
                        gap: 5px;
                        & *{
                            height: fit-content;
                        }
                        span{
                            text-align: center;
                            font-size: 1em;
                        }
                        input{
                            font-size: 1em;
                            // background: none;
                            border:none;
                            outline: none;
                            text-align: center;
                            box-sizing        : border-box;
                            display           : flex;
                            justify-content   : center;
                            position          : relative;
                            width             : 100%;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            margin            : 0;
                            cursor: pointer;
                            &::-webkit-calendar-picker-indicator {
                                display: none;
                                -webkit-appearance: none;
                                }
                        }
                            // input[type=date]::placeholder,
                            // input[type=time]::placeholder {
                            //     display: none;
                            // }
                            // input[type=date]::-webkit-inner-spin-button, 
                            // input[type=date]::-webkit-outer-spin-button,
                            // input[type=time]::-webkit-inner-spin-button, 
                            // input[type=time]::-webkit-outer-spin-button{
                            //     -webkit-appearance: none; 
                            //     display: none;
                            //     margin: 0; 
                            // }
                            // input[type=date]::-webkit-calendar-picker-indicator,
                            // input[type=time]::-webkit-calendar-picker-indicator {
                            // display: none;
                            // -webkit-appearance: none;
                            // }
                            // input[type=date]::-webkit-datetime-edit,
                            // input[type=time]::-webkit-datetime-edit {
                            // }
                            
                        // button{
                        //     position: relative;
                        //     border: none;
                        //     background: none;
                        //     cursor: pointer;
                        //     font-size: 1em;
                        //     input{
                        //         visibility:hidden;
                        //         position:absolute;
                        //         bottom:0;
                        //         left:0;
                        //     }
                        // }
                    }
                }
            }
            .c-d-r-choose-room{
                box-sizing: border-box;
                padding: 5px;
                height: 100%;
                display: flex;
                flex-direction: column;
                .c-d-r-choosable-room{
                    width: 100%;
                    // height: 100%;
                    display: flex;
                    gap: 5px;
                    flex-wrap: wrap;
                    .room-item{
                        position:relative;
                        &:hover{
                            &::before{
                                content:'';
                                position: absolute;
                                top:0;
                                display: block;
                                background-color: rgba(0,0,0,0.5);
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                            }
                        }
                        &.clicked, &.clicked:hover {
                            outline: 4px solid red;
                        }
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        box-sizing: border-box;
                        background-color: var(--main-color-light);
                        border-radius: 10px;
                        // min-width:80px;
                        padding: 4px;
                        cursor: pointer;
                        img{
                            border-radius: 10px 10px 0px 0px;
                            aspect-ratio: 3/2;
                            width: 100%;
                            object-fit: cover;
                        }
                        .room-i-title{
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            align-items: center;
                            gap: 3px;
                            margin-top: 6px;
                            width: fit-content;
                            font-size: 1em;
                            span{
                                font-weight: bold;
                                color: var(--main-color);
                            }
                            .room-i-roomNumber{
                                border-radius: 10px;
                                background-color: var(--main-color);
                                padding: 0px 10px;
                                font-weight: bold;
                                color: var(--main-color-light);
                                text-shadow: 1px 1px 1px var(--main-color-dark);
                            }
                        }
                    }
                    // .room-item{
                    //     display: flex;
                    //     flex-direction: column;
                    //     justify-content: center;
                    //     align-items: center;
                    //     width: 4em;
                    //     height: 2em;
                    //     border: none;
                    //     border-radius: 8px;
                    //     font-size: 1em;
                    //     font-weight: bold;
                    //     color: var(--main-color-dark);
                    //     cursor: pointer;
                    //     &.availible{
                    //         background-color: lightgreen;
                    //     }
                    //     &.waiting{
                    //         background-color: yellow;
                    //     }
                    //     &.clickable{
                    //         cursor: pointer;
                    //     }
                    //     // &:hover{
                    //     //     outline:4px solid red;
                    //     // }
                    //     &.clicked, &.clicked:hover {
                    //         outline: 4px solid red;
                    //     }
                    // }
                }
            }
            .c-d-r-output{
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px;
                *{
                    font-size: 1.2em;
                    font-weight: bold;
                    color: var(--main-color-super-dark);
                }
        
            }
            .c-d-r-title{
                padding: 0px 0px 10px 0px;
                height: fit-content;
                display: flex;
                justify-content: center;
                font-size: 1.2em;
                font-weight: bold;
                color: var(--main-color-dark);
            }
        }
    }
    .c-d-r-layer-2{
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}