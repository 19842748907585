.navbar{
    z-index: 2;
    width: 100vw;
    height: var(--navbar-height);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: var(--main-color);
    align-items: center;
    position: fixed;
    top: 0;
    box-sizing: border-box;
    padding-top: 10px;
    .navbar-container{
        z-index: 3;
        box-sizing: border-box;
        background: linear-gradient(to top,transparent 30%,var(--main-color-dark));
        width: 95%;
        max-width: 1024px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        box-shadow: 
        inset 0px 30px 5px -30px var(--main-color-dark),
        inset 10px 0px 5px -10px var(--main-color-dark),
        inset -10px 0px 5px -10px var(--main-color-dark);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 10px 0px;
        .left{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .line-btn{
                margin: 5px 0px 5px 15px;
                padding: 8px 12px 8px 12px;
                color: var(--main-color-light);
                background-color: transparent;
                box-shadow: 1px 1px 5px 1px var(--main-color-dark);
                border: none;
                border-radius: 100vmin;
                cursor: pointer;
                &:hover{
                    color: var(--main-color-dark);
                    background-color: rgba(256, 256, 256, 0.2);
                }
            }
        }
        .middle{
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            overflow: hidden;
        }
        .right{
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .menu-btn{
                // height: 70%;
                // aspect-ratio: 1/1;
                height: 2em;
                width: 2em;
                margin: 5px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                box-shadow: 1px 1px 5px 1px var(--main-color-dark);
                border: none;
                border-radius: 100vmin;
                cursor: pointer;
                &:hover{
                    *{
                        color: var(--main-color-dark);
                    }
                    background-color: rgba(256, 256, 256, 0.2);
                }
                .menu-icon{
                    transition: rotate 0.3s ease-in;
                    color: var(--main-color-light);
                    rotate: 0deg;
                    &.open{
                        rotate: 90deg;
                    }
                }
            }
        }
    }
    .navbar-bottom-shadow{
        z-index: 1;
        bottom: -10px;
        position: absolute;
        box-sizing: border-box;
        width: 95%;
        max-width: 1024px;
        height: 10px;
        background: linear-gradient(to top,transparent,var(--main-color-dark));
        box-shadow: 
        inset 10px 0px 5px -10px var(--main-color-dark),
        inset -10px 0px 5px -10px var(--main-color-dark);
    }
    .menu-items{
        z-index: 2;
        background-color: var(--main-color);
        position: absolute;
        width: 95%;
        max-width: 1024px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding-top: 20px;
        padding-bottom: 50px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        box-shadow: 
            inset 0px -30px 5px -30px var(--main-color-dark),
            inset 10px 0px 5px -10px var(--main-color-dark),
            inset -10px 0px 5px -10px var(--main-color-dark);
        
        visibility: hidden;
        opacity: 0;
        top: 0;
        transform: translateY(0px);
        transition: 
            visibility 0s 1s,
            opacity 1s ease-in-out, 
            transform 1s ease-in-out;

        &.open{
            opacity: 1;
            transform: translateY(55px);
            visibility: visible;
            transition: 
            visibility 1s, 
            opacity 1s ease-in-out, 
            transform 1s ease-in-out;
        }
        .menu-item{
            color: var(--main-color-light);
            box-sizing: border-box;
            width: 50%;
            height: fit-content;
            padding: 10px 10px 10px 10px;
            text-align: center;
            letter-spacing: 5px;
            border-radius: 20px;
            box-shadow: inset 2px 2px 10px 1px var(--main-color-dark);
            cursor: pointer;
            font-size: 1.2em;
            &.login-btn{
                background-color: var(--color-good);
            }

            &.logout-btn{
                background-color: var(--color-warning);
            }
        }
    }
    .menu-disable-area{
        z-index: 1;
        position: absolute;
        top: 0;
        min-width: 100vw;
        height: 100%;
        min-height: 100vh;
    }
}